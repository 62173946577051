import { createTheme } from '@mui/material/styles';
import CeraProLight from './fonts/CeraProLight.woff';
import CeraProMedium from './fonts/CeraProMedium.woff';
import CeraProBold from './fonts/CeraProBold.woff';
import CeraProBlack from './fonts/CeraProBlack.woff';
import CeraProItalic from './fonts/CeraProItalic.woff';
import CeraProBlackItalic from './fonts/CeraProBlackItalic.woff';
import OpenSansLight from './fonts/open-sans-v34-latin-300.woff';
import OpenSansMedium from './fonts/open-sans-v34-latin-regular.woff';
import OpenSansBold from './fonts/open-sans-v34-latin-700.woff';
import OpenSansBlack from './fonts/open-sans-v34-latin-800.woff';
import OpenSansItalic from './fonts/open-sans-v34-latin-italic.woff';
import OpenSansBlackItalic from './fonts/open-sans-v34-latin-800italic.woff';

export default createTheme({
  palette: {
    error:{
      main:'#B00020'
    }
  },
  typography: {
    fontFamily: 'open-sans, verdana, sans-serif',
    htmlFontSize: 16,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'CeraPro';
          font-style: normal;
          font-display: swap;
          font-weight: 200;
          src: local('CeraProLight'), url(${CeraProLight}) format('woff');
        }
        
        @font-face {
          font-family: 'CeraPro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('CeraProMedium'), url(${CeraProMedium}) format('woff');
        }
        
        @font-face {
          font-family: 'CeraPro';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('CeraProBold'), url(${CeraProBold}) format('woff');
        }
        
        @font-face {
          font-family: 'CeraPro';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('CeraProBlack'), url(${CeraProBlack}) format('woff');
        }
        
        @font-face {
          font-family: 'CeraPro';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: local('CeraProItalic'), url(${CeraProItalic}) format('woff');
        }
        
        @font-face {
          font-family: 'CeraPro';
          font-style: italic;
          font-display: swap;
          font-weight: 800;
          src: local('CeraProBlackItalic'), url(${CeraProBlackItalic}) format('woff');
        }

        @font-face {
          font-family: 'open-sans';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('OpenSansLight'), url(${OpenSansLight}) format('woff');
        }
        
        @font-face {
          font-family: 'open-sans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('OpenSansMedium'), url(${OpenSansMedium}) format('woff');
        }
        
        @font-face {
          font-family: 'open-sans';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('OpenSansBold'), url(${OpenSansBold}) format('woff');
        }
        
        @font-face {
          font-family: 'open-sans';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('OpenSansBlack'), url(${OpenSansBlack}) format('woff');
        }
        
        @font-face {
          font-family: 'open-sans';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: local('OpenSansItalic'), url(${OpenSansItalic}) format('woff');
        }
        
        @font-face {
          font-family: 'open-sans';
          font-style: italic;
          font-display: swap;
          font-weight: 800;
          src: local('OpenSansBlackItalic'), url(${OpenSansBlackItalic}) format('woff');
        }
      `,
    },
  },
});
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

const SessionTimeout = ({ _t }) => {
  const [searchParams] = useSearchParams();
  const [isSatellite, setIsSatellite] = useState(0);
  const handleClose = () => {
    let newWindow = open(location, "_self");
    newWindow.close();
    return false;
  };
  useEffect(() => {
    if (searchParams.get("isSatellite")) {
      setIsSatellite(1);
    }
  }, []);

  return (
    <div className="tp-portal__form" data-sel="logout-form">
      <div className="tp-portal__form-content">
        <div className="tp-portal__logo-top">
          <img src="/public/images/login/logo-top.svg" />
        </div>
        <div className="tp-portal__header">
          <div className="tp-portal__header__logo-blue">
            <img src="/public/images/login/logo-top-blue.svg" />
          </div>
          <h1>{_t("session.logout.header")}</h1>
        </div>
        <div className="tp-portal__messages is--success" data-sel="message">
          <p>{_t("session.logout.message")}</p>
        </div>
        <div className={"input-buttons__wrap"}>
          {isSatellite ? (
            <div
              data-sel="back-to-portal"
              className={"input-buttons__wrap__button"}
              onClick={handleClose}
            >
              {_t("session.logout.close")}
            </div>
          ) : (
            <Link
              data-sel="back-to-portal"
              className={"input-buttons__wrap__button"}
              to={"/"}
            >
              {_t("session.logout.backToPortal")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionTimeout;

import React from 'react';
import validateField from '../../Utils/FieldValidator';
import { retrieve } from './../../Utils/Retrieve';
import Configs from './../../../config/default';
import Loading from '../../Utils/Loading';
import ErrorIcon from '@mui/icons-material/Error';

class EditEmail extends React.Component {
  constructor(props) {
    super(props);
    const errorMapper = {
      'Incomplete or not valid payload': ['portal.IncompleteOrNotValidPayload'],
      'too many requests for this user.': ['portal.tooManyRequests'],
      'required': [],
      'not empty': [],
      'email address already in use': ['portal.emailAddressAlreadyInUse']
    };
    this.state = {
      field: {
        name: 'email',
        required: true,
        type: 'text',
        classes: 'is--secure',
        errors: [],
        value: props.profile.mail
      },
      errors: [],
      errorMessages: null,
      submitReady: false,
      token: props.profile.accessToken,
      errorMapper,
      updateDataCountAfterSave: 1
    };
  }

  onChange(e) {
    const { _t } = this.props;
    const { value } = e.target;
    const { field: { required }, updateDataCountAfterSave } = this.state;
    const rules = {
      'valid_email': true
    };
    const errors = validateField({ rules, value, required });
    const errMapper = {
      'valid_email': _t('portal.account.changeEmail.emailAddressInvalid', value)
    };
    const errorMessages = errors.map(e => errMapper[e.method] || e.method + value);
    
    this.setState({
      field: {
        value
      },
      errors,
      errorMessages,
      submitReady: !errors.length,
      updateDataCountAfterSave: updateDataCountAfterSave + 1
    });
  }

  async _onSubmit() {
    const { submitReady, errorMapper, field, token } = this.state;
    const { profile: { mail }, _t } = this.props;
    if (submitReady && mail !== field.value) {
      this.setState({ submitReady: false, isLoading: true });
      let serverError = '';
      const { value } = field;
      const { routes: { gum } } = Configs;
      const { statusText, data: { error } } =
        await retrieve(token, `${gum.general}/self/change_email`, 'POST', { 'new_email': value });
      if (statusText === 'OK') {
        this.setState({
          serverError,
          saveSuccess: true,
          isLoading: false,
          updateDataCountAfterSave: 0
        });
      } else {
        const { message, organization } = error;
        serverError = message || '';
        serverError = errorMapper[serverError] || [serverError];
        serverError = serverError.map((msg) => {
          return _t(msg, organization || '');
        });

        return this.setState({
          saveSuccess: false,
          serverError,
          isLoading: false,
          submitDisabled: false
        });
      }
    }
  }

  _renderSuccess() {
    const { saveSuccess } = this.state;
    const { _t } = this.props;
    const isSuccess = saveSuccess ? 'is--success' : '';
    if (saveSuccess) {
      return (
        <>
          <div data-sel="email-success" className={`tp-portal__messages ${isSuccess}`}>
            <p>
              { _t('tokenHasBeenSentSuccessfully') }<br />
              { _t('portal.account.changeEmail.successHint') }
            </p>
          </div>
        </>
      );
    }
    return null;
  }
  
  _renderError() {
    const { serverError } = this.state;
    if (serverError) {
      return (
        <>
          <div data-sel="email-errors" className={'tp-portal__messages has--errors'}>
            {serverError.map((msg, i) => {
              return <p key={i}>{msg}</p>;
            })}
          </div>
        </>
      );
    }
    return null;
  }

  render() {
    const { _t, fieldIndex, onClose, profile: { mail }, emailInfo, keyIndex, openEdit, isFederated, isCicLinked } = this.props;
    const { isLoading, updateDataCountAfterSave, field, submitReady, saveSuccess, errors, errorMessages } = this.state;
    const { editable, editMode, label, value } = emailInfo[0];
    if (isLoading) {
      return <Loading />;
    }
    return (
      <div className={`profile__items__item ${editable ? 'with--arrow' : ''}`} key={keyIndex}>
        <div className={'profile__items__item__key'} title={label}>{label}</div>
        {editable && editMode ?
          <div className={'profile__items__item__value'}>
            <h3>{_t('changeEmailAddress')}</h3>
            <div className="tp-portal__inputs js--inputs">
              {this._renderSuccess()}
              {this._renderError()}
              <div className="input-field__wrap is--required is--email" data-sel="email-input-wrapper" >
                <label className="input-field__wrap__label">{_t('portal.account.passwordAndSecurity.changeEmail.accountEmail')}</label>
                <input 
                  type="text" 
                  name={field.name} 
                  className="input-field__wrap__input" 
                  value={field.value} 
                  onChange={e => this.onChange(e)} 
                />
                {errors.length ? 
                  <div className="input-field__wrap__errors">
                    <ul className="input-field__wrap__errors__list">
                      <li className="input-field__wrap__errors__list__item">{errorMessages.join('')}</li>
                    </ul>
                  </div> 
                  : 
                  null
                }
              </div>
              <div className="input-buttons__wrap">
                <p className="input-buttons__wrap__info-text">{_t('portal.form.required_fields')}</p>
                {saveSuccess && updateDataCountAfterSave < 1 ?
                  <button
                    data-sel="email-ok"
                    className={'input-buttons__wrap__button'}
                    onClick={() => onClose(fieldIndex)}
                  >{_t('portal.account.passwordAndSecurity.editEmailAndEditPassword.saveSuccess')}</button>
                  :
                  <button
                    className="input-buttons__wrap__button is--grey"
                    onClick={() => onClose(fieldIndex)}
                  >{ _t('cancel') }</button>
                }
                <button
                  data-sel="email-submit"
                  className={`input-buttons__wrap__button ${ submitReady && mail !== field.value ? '' : 'is--disabled' }`}
                  onClick={this._onSubmit.bind(this)}
                >{ _t('save') }</button>
              </div>
            </div>
          </div>
          :
          <div className={`profile__items__item__value ${editable ? '' : 'is--disabled'}`}>
            {value}
            {!editable ?
              <div className={`profile__items__item__value__notification`}>
                <ErrorIcon 
                  sx={{
                    marginRight: '5px',
                    fontSize: '15px', 
                    verticalAlign: 'middle', 
                    color: '#B00020', 
                    backgroundColor: 'white',
                    borderRadius: '20px'
                  }}
                />
                <small className={`profile__items__item__value__notification__message`}>
                  {isFederated ?
                    _t('portal.account.passwordAndSecurity.editEmail.federatedUserWarning')
                    :
                    null
                  }
                  {isCicLinked && !isFederated ?
                    _t('portal.account.passwordAndSecurity.editEmail.cicUserWarning')
                    :
                    null
                  }
                </small> 
              </div>
              :
              null
            } 
          </div>
        }
        {editable && !editMode ?
          <div className="profile__items__item__arrow" onClick={(e) => {e.preventDefault(); openEdit(keyIndex);}}/> 
          : 
          null
        }
      </div>
    );
  }
}

export default EditEmail;
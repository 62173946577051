import React from 'react';
import { retrieve } from './../../../Utils/Retrieve';
import Configs from './../../../../config/default';
import Loading from './../../../Utils/Loading';
import SetPasswordForm from '../../../General/Form/SetPasswordForm';
import { Link, Navigate } from 'react-router-dom';
import { getFieldValueById } from '../../../Utils/Fields';

class SetNewPassword extends React.Component {
  constructor(props) {
    super(props);
    
    const { activation: { username, token }, _t } = props;

    this.state = {
      token,
      fields: [
        {
          id: 'newPassword',
          label: _t('newPassword'),
          name: 'password',
          required: true,
          type: 'password',
          classes: 'is--secure',
          errors: [],
          value: ''
        },
        {
          id: 'confirmPassword',
          label: _t('confirmPassword'),
          name: 'password1',
          required: true,
          type: 'password',
          classes: 'is--secure',
          errors: [],
          value: ''
        }],
      dataToSubmit: {
        token
      },
      username,
      pwPolicy: null,
      submitReady: false,
      serverError: '',
      isLoading: true,
      saveSuccess: false,
      doRedirect: false
    };
    this._updateData = this._updateData.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }
  
  componentWillUnmount() {
    this.cancelled = true;
  }
  
  componentDidMount() {
    const { pwPolicy } = this.state;
    if (!pwPolicy) {
      return this._getPwPolicy();
    }
  }
  
  async _getPwPolicy() {
    const { routes: { gum } } = Configs;
    const { username } = this.state;
    
    const { data, statusText } = await retrieve('', `${gum.public}/pwpolicy/${username}`, 'GET');
    if (statusText === 'OK') {
      let {
        result: {
          pw_digits,
          pw_lower_and_uppercase,
          pw_min_length,
          pw_special_chars_allowed,
          pw_special_chars_count,
        }
      } = data;
      
      !this.cancelled && this.setState({
        pwPolicy: {
          ...(!!pw_digits && { pw_digits: !!pw_digits }),
          ...(!!pw_lower_and_uppercase && { pw_lower_and_uppercase: !!pw_lower_and_uppercase }),
          pw_min_length,
          pw_special_chars: {
            allowed: pw_special_chars_allowed,
            count: pw_special_chars_count
          },
        },
        isLoading: false
      });
    } else {
      !this.cancelled && this.setState({
        pwPolicy: {},
        isLoading: false
      });
    }
  }

  _updateData(data) {
    const { submitReady, fields } = data;
    const values = {
      currentPassword: getFieldValueById(fields, 'currentPassword'),
      newPassword: getFieldValueById(fields, 'newPassword'),
      confirmPassword: getFieldValueById(fields, 'confirmPassword'),
    };
    const { dataToSubmit } = this.state;
    this.setState({
      submitReady,
      dataToSubmit: { ...dataToSubmit, ...values }
    });
  }

  _getRequestData(type) {
    const data = {
      'account-activation': {
        url: `${Configs.routes.gum.public}/registration`,
        method: 'PUT'
      },
      'forgot-password': {
        url: `${Configs.routes.gum.public}/change_password_via_token`,
        method: 'POST'
      }
    };
    return data[type];
  }
  
  async _onSubmit(e) {
    e.preventDefault();
    const { _t, errorMapper, type } = this.props;
    const { dataToSubmit, submitReady } = this.state;
    let serverError = '';
    if (submitReady) {
      this.setState({ submitReady: false, isLoading: true });
      const { url, method } = this._getRequestData(type);
      const requestPassword = await retrieve('', url, method, {
        password: dataToSubmit.newPassword,
        password1: dataToSubmit.confirmPassword,
        token: dataToSubmit.token
      });
      const { statusText, data: { error } } = requestPassword;
      if (statusText === 'OK') {
        this.setState({
          serverError,
          saveSuccess: true,
          isLoading: false
        });
      } else {
        const { message, organization } = error;
        serverError = message || '';
        serverError = errorMapper[serverError] || [serverError];
        serverError = serverError.map((msg) => {
          return _t(msg, organization || '');
        });
        
        const doRedirect = type === 'account-activation' && message === 'token expired';
        return this.setState({
          serverError,
          isLoading: false,
          submitDisabled: false,
          doRedirect
        });
      }
      
    }
  }
  
  _renderSuccess() {
    const { type } = this.props;
    const { saveSuccess } = this.state;
    const { _t } = this.props;
    const isSuccess = saveSuccess ? 'is--success' : '';
    if (saveSuccess) {
      return (
        <>
          <div className={`tp-portal__messages ${isSuccess}`}>
            <p>{type === 'account-activation'
              ? _t('portal.accountActivation.setPasswordSuccess')
              : _t('portal.resetPassword.changedSuccess')}</p>
          </div>
  
          <div className={'input-buttons__wrap'}>
            <Link to={'/'} className={'input-buttons__wrap__button'}>
              {_t('ok')}
            </Link>
          </div>
        </>
      );
    }
    return null;
  }
  
  _renderError() {
    const { _t, type } = this.props;
    const { serverError } = this.state;
    if (serverError) {
      return (
        <>
          <div className={'tp-portal__messages has--errors'}>
            {serverError.map((msg, i) => {
              return <p key={i}>{msg}</p>;
            })}
          </div>
          <div className={'input-buttons__wrap'}>
            <Link
              to={type === 'account-activation' ? '/' : '/service/forgot-password'}
              className={'input-buttons__wrap__button'}>
              {_t('ok')}
            </Link>
          </div>
        </>
      );
    }
    return null;
  }
  
  _renderContent() {
    const { fields, pwPolicy, saveSuccess, submitReady, serverError } = this.state;
    const { _t } = this.props;
    const isDisabled = !submitReady ? 'is--disabled' : '';
    if (saveSuccess || serverError) {
      return null;
    }
    const compProps = {
      ...this.props,
      pwPolicy,
      fields,
      updateData: this._updateData
    };
    
    return (
      <>
        <SetPasswordForm {...compProps} />
        <div className={'input-buttons__wrap'}>
          <p className={'input-buttons__wrap__info-text'}>{_t('portal.form.required_fields')}</p>
          <button className={`input-buttons__wrap__button ${isDisabled}`} onClick={this._onSubmit}>
            {_t('ok')}
          </button>
        </div>
      </>
    );
  }
  
  render() {
    const { token } = this.state;
    const { isLoading, doRedirect } = this.state;
    if (isLoading) {
      return <Loading/>;
    }
    if(doRedirect) {
      return <Navigate to='/service/resend-token' token={token} replace={true} />;
    }

    return (
      <>
        {this._renderSuccess()}
        {this._renderError()}
        <div className={'tp-portal__inputs js--inputs'}>
          {this._renderContent()}
        </div>
      </>
    );
  }
}

export default SetNewPassword;

import React from 'react';
import { Link } from 'react-router-dom';
import { retrieve } from './../../Utils/Retrieve';
import Configs from './../../../config/default';
import Loading from '../../Utils/Loading';


class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    const { activation: { token } } = props;
    let { activation: { error } } = props;
    if (!error && !token) {
      error = {
        message: 'no token provided'
      };
    }
    this.state = {
      error,
      token,
      isSuccess: false
    };
  }

  async componentDidMount() {
    let error = this.state.error,
      isSuccess = false;
    if(! error) {
      this.setState({
        isLoading: true
      });
      const { data, statusText } = await retrieve('',
        `${Configs.routes.gum.public}/change_email_via_token`, 'POST', { token: this.state.token });
      if(statusText === 'OK') {
        isSuccess = true;
      } else {
        error = data.error;
        if(!(error && error.length)) {
          error = {
            message: 'unknown error'
          };
        }
      }
    }
    this.setState({
      isLoading: false,
      error,
      isSuccess
    });
  }

  render() {
    const { _t } = this.props;
    return (
    <div className="tp-portal__form">
      <div className="tp-portal__form-content">
        <div className="tp-portal__logo-top">
          <img src="/public/images/login/logo-top.svg"/>
        </div>
        <div className="tp-portal__header">
          <div className="tp-portal__header__logo-blue">
            <img src="/public/images/login/logo-top-blue.svg"/>
          </div>
          <h1>{_t('portal.service.confirmEmail.title')}</h1>
        </div>
        { this._renderErrorOrSuccess() }
      </div>
    </div>
    );
  }

  _renderErrorOrSuccess() {
    const { _t } = this.props;
    const { error, isLoading, isSuccess } = this.state;
    const translationMapper = {
      'no token provided': ['portal.noTokenProvided'],
      'invalid token': ['portal.changeEmail.invalidToken'],
      'success': ['portal.service.confirmEmail.success'],
      'unknown error': ['unknownErrorRetryLater']
    };
    if (isLoading) {
      return <Loading/>;
    }
    if(isSuccess) {
      return <>
        <div className="tp-portal__messages is--success" data-sel="message">
          <p>{ translationMapper['success'].map(m => _t(m)).join(' ') }</p>
        </div>
          <Link
          data-sel="ok"
          className={'input-buttons__wrap__button'}
          to={'/account/password-and-security'}
        >OK</Link>
      </>;
    } else {
      if (error) {
        return <div className="tp-portal__messages has--errors" data-sel="message">
          <p>{translationMapper[error.message] ? translationMapper[error.message].map(m => _t(m)).join(' ') : error.message}</p>
        </div>;
      }
      else {
        return <div className="tp-portal__messages has--errors" data-sel="message">
          <p>{_t('unknown error')}</p>
        </div>;
      }
    }
  }
}

export default ConfirmEmail;

import React from 'react';
import { Link } from 'react-router-dom';
import Loading from './../../Utils/Loading';
import { getErrorMapper } from './errorMapper';
import ReqPasswordViaEmail from './_components/ReqPasswordViaEmail';
import SetNewPassword from './_components/SetNewPassword';

class ActivateAndSetPassword extends React.Component {
  constructor(props) {
    super(props);
    const { activation: { token, error }, type } = props;
    this.state = {
      type,
      token,
      error,
      errorMapper: getErrorMapper(type)
    };
  }
  
  componentWillUnmount() {
    this.cancelled = true;
  }
  
  componentDidUpdate(prevProps) {
    const { activation: { error: prevError, token: prevToken } } = prevProps;
    const { activation: { token, error } } = this.props;
    if (prevError !== error || prevToken !== token) {
      this.setState({ error, token });
    }
  }
  
  _renderContent() {
    const { _t, type } = this.props;
    const { token, error, errorMapper } = this.state;
    let serverError = '';
    if (error) {
      const { message, organization } = error;
      serverError = message || '';
      serverError = errorMapper[serverError] || [serverError];
      serverError = serverError.map((msg) => {
        return _t(msg, organization || '');
      });
      // const { currentapp } = Configs;
      return (
        <>
          <div className={'tp-portal__messages has--errors'}>
            {serverError.map((msg, i) => {
              return <p key={i}>{msg}</p>;
            })}
          </div>
          <div className={'input-buttons__wrap'}>
            <Link to={
              type === 'account-activation'
                ? '/'
                : '/service/forgot-password'
            } className={'input-buttons__wrap__button'}>
              {_t('ok')}
            </Link>
          </div>
        </>
      );
    } else if (!token && type === 'account-activation') {
      return (
        <div className={'tp-portal__messages has--errors'}>
          {_t('portal.accountActivation.invalidToken')}
        </div>
      );
    } else if (!token) {
      return <ReqPasswordViaEmail {...this.props} errorMapper={errorMapper}/>;
    } else if (token && !error) {
      return <SetNewPassword {...this.props} errorMapper={errorMapper}/>;
    }
    return <Loading/>;
  }
  
  render() {
    const { _t, type } = this.props;
    return (
      <div className={'tp-portal__form'}>
        <div className={'tp-portal__form-content'}>
          <div className={'tp-portal__logo-top'}>
            <img src={'/public/images/login/logo-top.svg'}/>
          </div>
          <div className={'tp-portal__header'}>
            <div className="tp-portal__header__logo-blue">
              <img src="/public/images/login/logo-top-blue.svg"/>
            </div>
            <h1>{
              type === 'account-activation'
                ? _t('portal.accountActivation.headline')
                : _t('portal.form.resetPassword.headline')
            }</h1>
          </div>
          {this._renderContent()}
        </div>
      </div>
    );
  }
}

export default ActivateAndSetPassword;

import React from 'react';
import { retrieve } from "./../../../Utils/Retrieve";
import Configs from "./../../../../config/default";
import Loading from './../../../Utils/Loading';
import Text from './../../Inputs/Text';

class ReqPasswordViaEmail extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      field: {
        label: 'E-Mail-Adresse',
        name: 'email',
        required: true,
        rules: { valid_email: 1 },
        type: 'text',
        classes: 'is--email',
        errors: []
      },
      submitDisabled: true,
      serverError: '',
      isLoading: false,
      saveSuccess: false
    };
    this._updateData = this._updateData.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }
  
  componentWillUnmount() {
    this.cancelled = true;
  }
  
  _updateData(data) {
    const { value, errors } = data;
    const { field } = this.state;
    this.setState({ field: { ...field, value, errors } }, () => {
      this._checkRequiredFields();
    });
  }
  
  _checkRequiredFields() {
    const { field : {errors, value, required} } = this.state;
    if (errors.length || (required && !value)) {
      return this.setState({ submitDisabled: true });
    }
    return this.setState({ submitDisabled: false });
  }
  
  async _onSubmit(e) {
    e.preventDefault();
    const { _t, errorMapper } = this.props;
    const { field, submitDisabled } = this.state;
    let serverError = '';
    if (!submitDisabled) {
      this.setState({ submitDisabled: true, isLoading: true });
      const { value: email } = field;
      const requestPassword = await retrieve('', `${Configs.routes.gum.public}/forgot_password`, 'GET', { email });
      const { statusText, data: { error } } = requestPassword;
      
      if (statusText === 'OK') {
        this.setState({
          serverError: '',
          saveSuccess: true,
          isLoading: false
        });
      } else {
        const { message, organization } = error;
        serverError = message || '';
        serverError = errorMapper[serverError] || [serverError];
        serverError = serverError.map((msg) => {
          return _t(msg, organization || '');
        });
        return this.setState({
          serverError,
          isLoading: false,
          submitDisabled: false
        });
      }
      
    }
  }
  
  _renderSuccess() {
    const { field, saveSuccess } = this.state;
    const { _t } = this.props;
    const isSuccess = saveSuccess ? 'is--success' : '';
    if (saveSuccess) {
      return (
        <div className={`tp-portal__messages ${isSuccess}`}>
          <p>{_t('portal.form.resetPassword.paragraph.1', field.value)}</p>
          <p>{_t('portal.form.resetPassword.paragraph.2', field.value)}</p>
          <p>{_t('portal.form.resetPassword.paragraph.3')}</p>
          <p>{_t('portal.form.resetPassword.paragraph.4')}</p>
        </div>
      )
    }
    return null;
  }
  
  _renderError() {
    const { serverError } = this.state;
    if (serverError) {
      return (
        <div className={`tp-portal__messages has--errors`}>
          {serverError.map((msg,i) => {
            return <p key={i}>{msg}</p>;
          })}
        </div>
      )
    }
    return null;
  }
  
  _renderContent() {
    const { field, saveSuccess, submitDisabled } = this.state;
    const { _t } = this.props;
    const isDisabled = submitDisabled ? 'is--disabled' : '';
    if (saveSuccess) {
      return null;
    }
    return (
      <>
        <Text {...this.props} field={field} updateData={this._updateData}/>
        <div className={'input-buttons__wrap'}>
          <p className={'input-buttons__wrap__info-text'}>{_t('portal.form.required_fields')}</p>
          <button className={`input-buttons__wrap__button ${isDisabled}`} onClick={this._onSubmit}>
            {_t('ok')}
          </button>
        </div>
      </>
    );
  }
  
  render() {
    const { _t } = this.props;
    const { isLoading } = this.state;
    if (isLoading) {
      return <Loading/>;
    }
    return (
      <>
        {this._renderSuccess()}
        {this._renderError()}
        <div className='tp-portal__inputs js--inputs'>
          {this._renderContent()}
        </div>
      </>
    );
  }
}

export default ReqPasswordViaEmail;

import React from 'react';
import { Link } from 'react-router-dom';

const Logout = ({ _t }) => {
  return (
    <div className="tp-portal__form" data-sel="logout-form">
      <div className="tp-portal__form-content">
        <div className="tp-portal__logo-top">
          <img src="/public/images/login/logo-top.svg"/>
        </div>
        <div className="tp-portal__header">
          <div className="tp-portal__header__logo-blue">
            <img src="/public/images/login/logo-top-blue.svg"/>
          </div>
          <h1>{_t('logout.header')}</h1>
        </div>
        <div className="tp-portal__messages is--success" data-sel="message">
          <p>{_t('logout.message')}</p>
        </div>
        <div className={'input-buttons__wrap'}>
          <Link
            data-sel="back-to-portal"
            className={'input-buttons__wrap__button'}
            to={'/'}
          >{_t('logout.backToPortal')}</Link>
        </div>
      </div>
    </div>
  )
}

export default Logout;

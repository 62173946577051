import React from 'react';
import validateField from '../../Utils/FieldValidator';
import SetPasswordFormInput from './SetPasswordFormInput';
import { getFieldValueById, getFieldById } from '../../Utils/Fields';

class SetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    const { fields } = props;
    const newPasswordField = getFieldById(fields, 'newPassword');
    const confirmPasswordField = getFieldById(fields, 'confirmPassword');
    if (! newPasswordField && confirmPasswordField) {
      console.log('This component unconditionally requires one field in the props with the id "newPassword" and one with the id "confirmPassword"')
    }
    this.state = {
      errors: [],
      fields,
      submitReady: false
    };
  }
  
  componentDidMount() {
    this._checkPolicy();
  }
  
  _checkPolicy() {
    const { fields } = this.state;
    const newPassword = getFieldValueById(fields, 'newPassword');
    const { updateData } = this.props;
    const field = {
      rules: { ...this.props.pwPolicy },
      value: newPassword,
      required: true
    };
    const errors = validateField(field);
    const submitReady = this.passwordEquals() && !errors.length;
    return this.setState({
      submitReady,
      errors
    }, () => {
      updateData(this.state);
    });
  }

  updateValue(id, val) {
    const { fields } = this.state;
    const newFields = fields.map(field => {
      if (field.id === id) {
        field.value = val;
      }
      return field;
    });
    this.setState({
      fields: newFields
    }, () => {
      this._checkPolicy();
    });
  }
  
  passwordEquals() {
    const { fields } = this.state;
    const newPassword = getFieldValueById(fields, 'newPassword');
    const confirmPassword = getFieldValueById(fields, 'confirmPassword');
    return newPassword === confirmPassword && newPassword.trim().length && confirmPassword.trim().length;
  }

  render() {
    const { fields } = this.state;
    const currentPasswordField = getFieldById(fields, 'currentPassword');
    const newPasswordField = getFieldById(fields, 'newPassword');
    const confirmPasswordField = getFieldById(fields, 'confirmPassword');

    const currentPassword = getFieldValueById(fields, 'currentPassword');
    const newPassword = getFieldValueById(fields, 'newPassword');
    const confirmPassword = getFieldValueById(fields, 'confirmPassword');

    const classesCurrentPw = [
      'input-field__wrap',
      'is--secure',
      currentPasswordField && currentPasswordField.required ? ['is--required'] : [],
    ];

    const classesNewPw = [
      'input-field__wrap',
      'is--secure',
      ...(newPasswordField.required ? ['is--required'] : [])
    ];
    
    const classesConfirmPw = [
      'input-field__wrap',
      'is--secure',
      ...(newPasswordField.required ? ['is--required'] : []),
      ...(!this.passwordEquals() && newPassword.length ? ['has--errors'] : [])
    ];

    return <form method={'POST'}>
      <div className="tp-portal__inputs js--inputs">
        { currentPasswordField
          ? <div data-sel={`input-wrapper-${currentPasswordField.name}`} className={classesCurrentPw.join(' ')}>
              <SetPasswordFormInput
                field={currentPasswordField}
                updateValue={this.updateValue.bind(this)}
                value={currentPassword} />
            </div>
          : null
        }
        <div data-sel={`input-wrapper-${newPasswordField.name}`} className={classesNewPw.join(' ')}>
          <SetPasswordFormInput
            field={newPasswordField}
            updateValue={this.updateValue.bind(this)}
            value={newPassword} />
        </div>
        <div data-sel={`input-wrapper-${confirmPasswordField.name}`} className={classesConfirmPw.join(' ')}>
          <SetPasswordFormInput
            field={confirmPasswordField}
            updateValue={this.updateValue.bind(this)}
            value={confirmPassword} />
          {!this.passwordEquals() && newPassword.length ? this._renderPassword1Error() : null}
        </div>
        <div data-sel={'pw-policy'} className={'input-pw-policy'}>
          <ul className="input-pw-policy__list">
            {this._renderPasswordErrors()}
          </ul>
        </div>
      </div>
    </form>;
  }
  
  _getPwPolicyRuleTranslation(rule, param) {
    const { _t } = this.props;
    if (rule === 'pw_special_chars') {
      return _t(`portal.pwPolicy.${rule}`, param.count, param.allowed);
    }
    return _t(`portal.pwPolicy.${rule}`, param);
  }
  
  _renderPasswordErrors() {
    const { errors } = this.state;
    const { pwPolicy } = this.props;
    return <> {
      Object.keys(pwPolicy).map(rule => {
        const isSuccess = !errors.find(e => e.method === rule) ? 'is--success' : '';
        return (
          <li key={rule} data-sel={rule} className={`input-pw-policy__list__item ${isSuccess}`}>
            {this._getPwPolicyRuleTranslation(rule, pwPolicy[rule])}
          </li>
        );
      })
  } </>;
  }
  
  _renderPassword1Error() {
    const { _t } = this.props;
    return (
      <div className="input-field__wrap__errors">
        <ul className="input-field__wrap__errors__list">
          <li className="input-field__wrap__errors__list__item">
            {_t('pw_not_matching')}
          </li>
        </ul>
      </div>
    );
  }
}

export default SetPasswordForm;

import React from 'react';
import Configs from './../../../config/default';
import { retrieve } from './../../Utils/Retrieve';
import Loading from '../../Utils/Loading';
class ResendToken extends React.Component {
  constructor(props) {
    super(props);
    const {location: {state: {token}}} = props;
    this.state = {
      token,
      tokenSendSuccess: 'notYetSent',
      isLoading: false
    };
  }

  async _resendToken() {
    const { token } = this.state;
    const { routes: { gum } } = Configs;
    this.setState({
      isLoading: true
    });
    const { statusText } = await retrieve('', `${gum.public}/resend_token`, 'POST', { token });
    if (statusText === 'OK') {
      this.setState({
        tokenSendSuccess: 'success',
        isLoading: false
      });
    } else {
      this.setState({
        tokenSendSuccess: 'failed',
        isLoading: false
      });
    }
  }

  render() {
    const { _t } = this.props;
    if (this.state.isLoading) {
      return <div className="tp-portal__form"><Loading /></div>;
    }
    return (
      <div className="tp-portal__form">
        <div className="tp-portal__form-content">
          <div className="tp-portal__logo-top">
            <img src="/public/images/login/logo-top.svg"/>
          </div>
          <div className="tp-portal__header">
            <div className="tp-portal__header__logo-blue">
              <img src="/public/images/login/logo-top-blue.svg"/>
            </div>
            <h1>{_t('portal.accountActivation.resetToken.title')}</h1>
          </div>
          { this._renderContent() }
        </div>
      </div>
    );
  }

  _renderContent() {
    const { tokenSendSuccess } = this.state;
    const { _t } = this.props;
    const renderedDiv = {
      notYetSent: (
        <>
          <div className="tp-portal__messages has--errors" data-sel="message">
            <p>{_t('portal.accountActivation.resetToken.message')}</p>
          </div>
          <div className={'input-buttons__wrap'}>
            <button className="input-buttons__wrap__button" data-sel="send" onClick={() => this._resendToken()}
            >{_t('portal.accountActivation.resetToken.button.label')}</button>
          </div>
        </>
      ),
      success: (
        <div className="tp-portal__messages is--success" data-sel="success">
          <p>
            {_t('portal.accountActivation.resetToken.success.message.paragraph.1')}
            &nbsp;
            {_t('portal.accountActivation.resetToken.success.message.paragraph.2')}
          </p>
        </div>
      ),
      failed: (
        <div className="tp-portal__messages has--errors" data-sel="error">
          <p>
            { _t('portal.accountActivation.resetToken.failed.paragraph.1') }
            &nbsp;
            { _t('portal.accountActivation.resetToken.failed.paragraph.2') }
          </p>
        </div>
      )
    };
    return renderedDiv[tokenSendSuccess];
  }
}

export default ResendToken;


import React, { useContext, useEffect } from "react";
import { AppContext } from "../../contexts/AppProvider";
import Signup from "./Signup";
import ResendToken from "./ResendToken";
import ActivateAndSetPassword from "./ActivateAndSetPassword";
import ExpiredPassword from "./ExpiredPassword";
import ValidateToken from "../General/ValidateToken";
import ConfirmEmail from "./ConfirmEmail";
import Logout from "./Logout";
import SessionTimeout from "./SessionTimeout";

const Service = (props) => {
  const { setServicePage } = useContext(AppContext);

  useEffect(() => {
    setServicePage(true);
    document.body.classList.add("is--service");
    return () => {
      document.body.classList.remove("is--service");
      setServicePage(false);
    };
  });

  return (
    <div className="tp-portal">
      <div className="tp-portal__logo">
        <div className="tp-portal__logo-top">
          <img src="/public/images/login/logo-top.svg" />
        </div>
      </div>
      {props.location.pathname === "/service/signup" ? (
        <Signup {...props} />
      ) : props.location.pathname === "/service/resend-token" ? (
        <ResendToken {...props} />
      ) : props.location.pathname === "/service/forgot-password" ? (
        <ValidateToken {...props} type={"forgot-password"}>
          <ActivateAndSetPassword />
        </ValidateToken>
      ) : props.location.pathname === "/service/password-expired" ? (
        <ExpiredPassword {...props} />
      ) : props.location.pathname === "/service/account-activation" ? (
        <ValidateToken {...props} type={"account-activation"}>
          <ActivateAndSetPassword />
        </ValidateToken>
      ) : props.location.pathname === "/service/confirm-email" ? (
        <ValidateToken {...props} type={"confirm-email"}>
          <ConfirmEmail />
        </ValidateToken>
      ) : props.location.pathname === "/service/logout" ? (
        <Logout {...props} />
      ) : props.location.pathname === "/service/session-timeout" ? (
        <SessionTimeout {...props} />
      ) : null}
    </div>
  );
};

export default Service;
